<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.consignment_manage") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-select v-model:value="selectedWarehouseNo"
                        :placeholder="$t('warehouse.warehouse_no')"
                        allow-clear
                        optionFilterProp="search-key"
                        show-search
                        @change="handleChangeStatus"
                        style="width: 250px;">

                <a-select-option v-for="item in warehouseNos"
                                :key="item.value"
                                :title="`${item.text1}(${item.text})`"
                                :search-key="item.text1 + item.text"
                                :value="item.value">
                  {{item.text1}}({{item.text}})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <SearchProduct ref="refSearchProduct"></SearchProduct>
            </a-col>
            <a-col>
              <a-input v-model:value="seSku"
                     placeholder="SESKU"
                     allow-clear />
            </a-col>
            <a-col>
              <a-button type="primary"
                      :loading="productListLoading"
                      @click="handleChangeStatus">{{$t('common.query')}}</a-button>
            </a-col>
            <a-col>
              <a-button type="ghost"
                      :loading="productListLoading"
                      @click="handleDownloadExcel">{{$t('common.export')}}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="link"
                    :loading="productListLoading"
                    @click="hanldeExportProductWarehouseTime">{{$t('common.export_product_in_the_warehouse_time')}}</a-button>
        </a-col>
      </a-row>
      <div class="mt-3">
        <a-table :columns="columns"
                 :data-source="productList"
                 :scroll="{ x:400,y: wrap.contentHeight - 148}"
                 size="small"
                 :pagination="false"
                 :loading="productListLoading"
                 class="product-table">

          <template #productNameAndSku>
            <div>
              {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
            </div>
          </template>
          <template #warehouseNo>
            <span>
              {{ $t("warehouse.warehouse_code") }}
            </span>
          </template>

          <template #packagingSize>
            <span>
              {{ $t("warehouse.product_dimensions") }}
            </span>
            <a-tooltip>
              <template #title>{{$t('warehouse.product_of_size')}}</template>
              <span class="ml-1 mr-1">
                <QuestionCircleOutlined />
              </span>
            </a-tooltip>
          </template>
          <template #packagingWeight>
            <span>
              {{ $t("warehouse.product_weight") }}
            </span>
            <a-tooltip>
              <template #title>{{$t('warehouse.product_contains_the_weight_of_the_package')}}</template>
              <span class="ml-1 mr-1">
                <QuestionCircleOutlined />
              </span>
            </a-tooltip>
          </template>

          <template #inWarehousePackagingSize>
            <span>
              {{ $t("warehouse.in_warehouse_product_size") }}
            </span>
            <a-tooltip>
              <template #title>{{$t('warehouse.product_of_size')}}</template>
              <span class="ml-1 mr-1">
                <QuestionCircleOutlined />
              </span>
            </a-tooltip>
          </template>

          <template #inWarehousePackagingWeight>
            <span>
              {{ $t("warehouse.in_warehouse_product_weight") }}
            </span>
            <a-tooltip>
              <template #title>{{$t('warehouse.product_contains_the_weight_of_the_package')}}</template>
              <span class="ml-1 mr-1">
                <QuestionCircleOutlined />
              </span>
            </a-tooltip>
          </template>
          <template #awaitInWarehouseCount>
            <span>
              {{ $t("warehouse.await_in_warehouse_count") }}
            </span>
          </template>
          <template #onWarehouseCount>
            {{ $t('warehouse.on_warehouse_count') }}
          </template>
          <template #applicableTitle>
            {{ $t('warehouse.applicable') }}
          </template>
          <template #notApplicableTitle>
            {{ $t('warehouse.not_applicable') }}
            <CTooltip :title="$t('warehouse.not_applicable_tips')" />
          </template>
          <template #awaitOutWarehouseCount>
            <span>
              {{ $t("warehouse.await_out_warehouse_count") }}
            </span>
          </template>

          <template #productNameAndSkuCustom="{ record }">
            <a-space>
              <div class="table-list-img-common ">
                <c-image :src="record.imgSrc" />
              </div>
              <div>
                <div>{{ record.productName}}</div>
                <div>{{ record.productNo}}</div>
              </div>
            </a-space>
          </template>
        </a-table>
      </div>

    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import {
  Row, Col, Table, Select, Button, Form, Space, Tooltip, Input
} from "ant-design-vue";
import Content from "../components/Content.vue";
import CPager from "../components/CPager.vue";
import CImage from "../components/CImage.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import { getWarehouseNos, getInventoryList, exportDeliveryWarehouseInventory, exportInventoryListWithStockAge } from "../../api/modules/consignment/index";
import SearchProduct from "../components/SearchProduct.vue"
import { downloadFile } from '@/utils/downloader.js';
import { dateString } from '@/utils/general.js'
import { gToKg } from "../../utils/general";
export default ({
  name: "consignment_inventory",
  components: {
    SearchProduct,
    CImage,
    Content,
    CPager,
    CTooltip,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATooltip: Tooltip,
    ASpace: Space,
    AInput: Input,
  },
  setup () {

    //#region columns
    const columns = [
      {
        slots: {
          title: "productNameAndSku",
          customRender: "productNameAndSkuCustom",
        },
        width: 200,
      },
      {
        width: 100,
        title: 'SESKU',
        dataIndex: "seSku",
      },
      {
        dataIndex: "warehouseNo",
        slots: {
          title: "warehouseNo",
        },
        width: 150,
      },
      {
        dataIndex: "packagingSize",
        slots: {
          title: "packagingSize",
        },
        width: 150,
      },
      {
        dataIndex: "packagingWeight",
        slots: {
          title: "packagingWeight",
        },
        width: 150,
      },

      {
        dataIndex: "inWarehousePackagingSize",
        slots: {
          title: "inWarehousePackagingSize",
        },
        width: 150,
      },

      {
        dataIndex: "inWarehousePackagingWeight",
        slots: {
          title: "inWarehousePackagingWeight",
        },
        width: 150,
      },
      {
        dataIndex: "awaitInWarehouseCount",
        align: "center",
        slots: {
          title: "awaitInWarehouseCount",
        },
        width: 150,
      },
      {
        width: 300,
        slots: {
          title: 'onWarehouseCount',
        },
        children: [
          {
            dataIndex: "onWarehouseUsableCount",
            align: "center",
            width: 150,
            slots: {
              title: 'applicableTitle',
            }
          },
          {
            dataIndex: "onWarehouseUnusableCount",
            align: "center",
            width: 150,
            slots: {
              title: 'notApplicableTitle',
            }
          },
        ]
      },
      {
        dataIndex: "awaitOutWarehouseCount",
        align: "center",
        slots: {
          title: "awaitOutWarehouseCount",
        },
        width: 150,
      },
    ];
    //#endregion

    const refSearchProduct = ref();
    const refPage = ref();

    const state = reactive({
      warehouseNos: [],
      productListLoading: false,
    });

    const data = reactive({
      selectedWarehouseNo: null,
      seSku: null,
      productList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
    });


    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    }

    const handleChangeStatus = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      handleSearch();
    }

    const handleWarehouseNo = async () => {
      let res = await getWarehouseNos({ "warehouseType": "2" });
      state.warehouseNos = res.result;
    }

    const handleSearch = async () => {
      state.productListLoading = true;

      let searchData = {
        "productId": refSearchProduct.value.selectedProductId,
        "warehouseId": data.selectedWarehouseNo,
        "seSku": data.seSku,
        ...data.pageData
      };

      getInventoryList(searchData).then((res) => {
        if (res.result) {
          let { items, totalCount } = res.result;
          data.productList = items.map((x, index) => {
            return {
              key: x.productId,
              imgSrc: x.imgUrl,
              productNo: x.productNo,
              productName: x.productName,
              warehouseNo: x.warehouseNo,
              seSku: x.seSku,
              packagingSize: (!x.packingLength || !x.packingWidth || !x.packingHeight) ? "-" : x.packingLength + 'x' + x.packingWidth + 'x' + x.packingHeight + "cm",
              packagingWeight: !x.packingWeight ? "-" : gToKg(x.packingWeight) + "kg",
              inWarehousePackagingSize: (!x.inwPackingLength || !x.inwPackingLength || !x.inwPackingWidth) ? "-" : x.inwPackingLength + 'x' + x.inwPackingWidth + 'x' + x.inwPackingHeight + "cm",
              inWarehousePackagingWeight: !x.inwPackingWeight ? "-" : gToKg(x.inwPackingWeight) + "kg",
              awaitInWarehouseCount: x.awaitInWarehouseCount,
              onWarehouseCount: x.onWarehouseCount,
              onWarehouseUsableCount: x.onWarehouseUsableCount,
              onWarehouseUnusableCount: x.onWarehouseCount - x.onWarehouseUsableCount,
              awaitOutWarehouseCount: x.awaitOutWarehouseCount,
            }
          })
          data.pageData.totalCount = parseInt(totalCount);
        } else {
          data.productList = [];
          data.pageData.totalCount = 0;
        }

      }).finally(() => {
        state.productListLoading = false;
      });

    }

    const handleDownloadExcel = () => {
      state.productListLoading = true
      let url = exportDeliveryWarehouseInventory()
      const postData = {
        "productId": refSearchProduct.value.selectedProductId,
        "warehouseId": data.selectedWarehouseNo,
        "seSku": data.seSku,
      };
      downloadFile(url, `consignment_inventory_${dateString()}.csv`, "POST", postData)
        .then(() => { })
        .catch(() => { })
        .finally(() => {
          state.productListLoading = false
        })
    }

    const hanldeExportProductWarehouseTime = () => {
      state.productListLoading = true
      let url = exportInventoryListWithStockAge()
      const postData = {
        "productId": refSearchProduct.value.selectedProductId,
        "warehouseId": data.selectedWarehouseNo,
        "seSku": data.seSku,
      };
      downloadFile(url, `consignment_inventory_age_${dateString()}.csv`, "POST", postData)
        .then(() => {
          state.productListLoading = false
        })
        .catch(() => {
          state.productListLoading = false
        })
    }

    onMounted(async () => {
      handleWarehouseNo();
      handleSearch();
    })

    return {
      columns,
      refPage,
      refSearchProduct,
      ...toRefs(state),
      ...toRefs(data),

      handlePage,
      handleSearch,
      handleWarehouseNo,
      handleChangeStatus,
      handleDownloadExcel,
      hanldeExportProductWarehouseTime
    };
  }
})
</script>

<style lang="less" scoped>
</style>