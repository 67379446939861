<template>
  <a-tooltip :title="title" :color="color">
    <span v-if="text" :style="textStyle">{{ text }}</span>
    <template v-else>
      <QuestionCircleOutlined :style="textStyle" />
    </template>
  </a-tooltip>
</template>

<script>
import { defineComponent } from 'vue'
import { Tooltip } from 'ant-design-vue';

export default defineComponent({
  components: {
    ATooltip: Tooltip
  },
  props: {
    title: {
      type: String,
      default: 'title'
    },
    text: String,
    textStyle: {
      type: [String, Object],
      default: {
        fontSize: '13px',
        color: '#404040'
      }
    },
    color: {
      type: String,
      default: '#404040'
    }
  },
})
</script>
